// ==========================================================================
// Materialize theme variables
// ==========================================================================
//
// Table of Contents:
//
//  1. Colors
//  2. Sidenav

//Colors

//Sidenav
$sidenav-hover-bg: rgba(255,255,255, 0.04);
$sidenav-open-bg: rgba(255,255,255, 0.04);
$sidenav-active-text-color: $white;
$sidenav-active-bg: color("light-blue" , "darken-2");
$sidenav-img-bg: url('../../images/gallery/12.png');
