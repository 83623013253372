/*----------------------------------------
	Badges
------------------------------------------*/

span.badge {
   color: #fff;
   &.pill {
      font-size: 0.8rem;
      min-width: 1rem;
      border-radius: 50%;
      line-height: 20px;
      height: 20px;
      border-radius: 9px;
   }
}

// Badges-navbar

#badges-navbar{
   nav{
      .brand-logo{
         font-size: 2.1rem;
      }
   }
}