/*----------------------------------------
    Slideout - Right side chat slide out
------------------------------------------*/
#right-search .input-field {
   margin-top: 0;
}
#slide-out-right {
   .tab {
      a {
         padding: 0 !important;
      }
   }

   &.sidenav {
      width: $right-sidenav-width;
      top: 0px;
      padding-bottom: 0px;
      li {
         a.active {
            background: none;
            box-shadow: none;
         }
      }
      .slide-out-right-body {
         height: calc(100vh - 60px);
         overflow-y: hidden;
         width: 100%;
      }
      #messages {
         padding: 0.7rem;
         .header-search-input {
            border: none;
            background-color: color("grey", "lighten-3");
            width: 86%;
            margin: 1rem;
            border-radius: 2rem;
            padding-left: 1rem;
            height: 2.8rem;
         }
         .collection {
            max-height: 100% !important;
            .collection-item {
               border: none;
            }
            ul.collection {
               max-height: 100% !important;
               border: none;
               overflow: hidden;
               li {
                  display: flex;
                  cursor: pointer;
                  padding-top: 1.2rem;
                  .user-content {
                     margin-left: 1rem;
                     margin-top: 0.4em;
                  }
                  .secondary-content {
                     color: color("indigo", "lighten-4");
                     top: 1.6rem;
                  }
                  &:hover {
                     background-color: color("grey", "lighten-4");
                  }
               }
            }
         }
      }
   }

   top: $navbar-height;
   overflow: hidden;

   .fixed-line-height {
      line-height: 24px;
   }

   #chatapp {
      li {
         line-height: 35px !important;
         min-height: 70px !important;
      }
      .sidenav li {
         line-height: 20px !important;
      }
   }
}
.chat-close-collapse {
   padding: 5px 15px 0 0;
}
.icon-bg-color {
   height: 35px;
   width: 35px;
   border-radius: 50%;
   text-align: center;
   padding-top: 8px;
   font-size: 20px;
}
.border-bottom-1 {
   border-bottom: 1px solid color("grey", "lighten-4");
}
#activity {
   ul.collection {
      max-height: 100% !important;
   }
   .collection::before {
      position: absolute;
      height: 100%;
      left: 4%;
      top: 1rem;
      width: 1px;
      margin-left: -1px;
      content: "";
      background-color: #e0e0e0;
   }
   .collection-item::before {
      content: "lens";
      font-size: 1rem;
      color: #e0e0e0;
      position: absolute;
      left: 1.6%;
      border-radius: 100%;
      font-family: "Material Icons";
   }
   .collection {
      border: none;
      .collection-item {
         border: none;
         padding-left: 2rem;
         margin-bottom: 1rem;
         p {
            font-size: 0.9rem;
         }
         .badge {
            float: none;
            margin: 0px;
            padding: 0.3rem 0.5rem 0.4rem 0.5rem;
         }
         .secondary-content {
            color: color("indigo", "lighten-4");
            font-size: 0.9rem;
         }
      }
   }
}

.slide-out-right-sidenav-chat {
   width: $right-sidenav-width;
   top: 0px;
}

#settings {
   ul.collection {
      max-height: 100% !important;
   }
}

@media #{$medium-and-down} {
   #slide-out-right {
      top: 0;
      &.sidenav {
         height: 100% !important;
      }
   }
}

// Slide Out Chat

#slide-out-chat {
   .chat-head,
   .chat-footer {
      // position: fixed;
      z-index: 1;
      background-color: color("blue-grey", "lighten-5");
      width: 100%;
   }
   .chat-footer {
      bottom: -0.6rem;
      form{
         background-color: color("blue-grey", "lighten-5");
      }
      input {
         background-color: $white;
         border: none;
         border-radius: 3rem;
         padding-left: 1rem;
         width: 80%;
         margin-left: -2rem;
      }
      label {
         margin-top: -0.7rem;
         margin-left: 2rem;
      }
      label:not(.label-icon).active {
         display: none;
      }
      i {
         cursor: pointer;
      }
      a {
         background: transparent;
         box-shadow: none;
         width: 40px;
         position: absolute;
         line-height: 2rem;
      }
   }
   .speech-bubble {
      position: relative;
      background: color("blue-grey", "lighten-5");
      border-radius: 0.3rem;
      margin-left: 1rem;
   }
   .speech-bubble:after {
      content: "";
      position: absolute;
      left: -9px;
      top: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-right-color: color("blue-grey", "lighten-5");
      border-left: 0;
      margin-top: -20px;
   }
   .speech-bubble-right {
      position: relative;
      background-color: $primary-color;
      border-radius: 0.3rem;
      margin-left: 1rem;
      color: $white;
   }
   .speech-bubble-right:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-left-color: $primary-color;
      border-right: 0;
      margin-top: -16px;
      margin-right: -8px;
   }

   .chat-body{
      ul.collection{
         top: 0;
         margin-bottom: 0;
         margin-top: 0;
      }
   }

   .collection {
      height: calc(100vh - 129px);
      top: 48px;
      .collection-item {
         min-height: auto;
         border: none;
         margin-bottom: 1rem;
         .user-content {
            padding: 1rem;
         }
      }
   }
}


// To remove horizontal line in sm

@media(max-width: 600px){
    #slide-out-right{
        &.sidenav{
            box-shadow: none;
        }
    }
}