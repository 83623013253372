/*----------------------------------------
	Avatar
------------------------------------------*/

$avatar-size:            28px;
$avatar-status-size:     9px;

$avatar-size-lg:        144px;
$avatar-status-size-lg: 20px;

$avatar-size-md:        40px;
$avatar-status-size-md: 10px;

$avatar-size-sm:        32px;
$avatar-status-size-sm: 8px;

$avatar-size-xs:        24px;
$avatar-status-size-xs: 7px;

$avatar-size-50:        50px;
$avatar-status-size-50: 12px;

$avatar-size-100:        100px;
$avatar-status-size-100: 20px;

$avatar-online-color:    color("green", "accent-3") !default;
$avatar-off-color:       color("grey", "lighten-1") !default;
$avatar-busy-color:      color("red", "accent-3") !default;
$avatar-away-color:      color("amber", "accent-3") !default;

.avatar-status {
  position: relative;
  display: inline-block;
  line-height: 50px;
  width: $avatar-size;
  white-space: nowrap;
  border-radius: 50px;
  vertical-align: bottom;

  i {
    position: absolute;
    right: -2px;
    bottom: 18px;
    width: $avatar-status-size !important;
    height: $avatar-status-size !important;
    border: 1px solid $white;
    border-radius: 100%;
  }
  span.text-circle{
    text-align: center;
    vertical-align: middle;  
    color: $white;
    font-size: 1.2rem;  
    background: color("grey", "lighten-5");
    display: table-cell;
  }

  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    background: #e6e6e6;
    border: 0 none;
    border-radius: 1000px;
  }
}


// Status Modifier
// ========================================================================

.avatar-online {
  i {
    background-color: $avatar-online-color;
  }
}

.avatar-off {
  i {
    background-color: $avatar-off-color;
  }
}

.avatar-busy {
  i {
    background-color: $avatar-busy-color;
  }
}

.avatar-away {
  i {
    background-color: $avatar-away-color;
  }
}

// Size Modifier
// ========================================================================
.avatar-status {
  &.avatar-100 {
    width: $avatar-size-100;
    span.text-circle{
      width: $avatar-size-100;
      height: $avatar-size-100;
    }
    i {
      height: $avatar-status-size-100;
      width: $avatar-status-size-100;
    }
  }

  &.avatar-50 {
    line-height: 0;
    width: $avatar-size-50;
    span.text-circle{
      width: $avatar-size-50;
      height: $avatar-size-50;
    }
    i {
      height: $avatar-status-size-50 !important;
      width: $avatar-status-size-50 !important;
      right: -1px;
      bottom: 21px;
    }
  }

  &.avatar-lg {
    width: $avatar-size-lg;
    span.text-circle{
      width: $avatar-size-lg;
      height: $avatar-size-lg;
    }
    i {
      right:0;
      bottom:0;
      height: $avatar-status-size-lg;
      width: $avatar-status-size-lg;
    }
  }

  &.avatar-md {
    width: $avatar-size-md;
    span.text-circle{
      width: $avatar-size-md;
      height: $avatar-size-md;
    }
    i {
      right:0;
      bottom:0;
      height: $avatar-status-size-md;
      width: $avatar-status-size-md;
    }
  }

  &.avatar-sm {
    width: $avatar-size-sm;
    span.text-circle{
      width: $avatar-size-sm;
      height: $avatar-size-sm;
    }
    i {
      height: $avatar-status-size-sm;
      width: $avatar-status-size-sm;
    }
  }

  &.avatar-xs {
    width: $avatar-size-xs;
    span.text-circle{
      width: $avatar-size-xs;
      height: $avatar-size-xs;
    }
    i {
      height: $avatar-status-size-xs;
      width: $avatar-status-size-xs;
    }
  }
}
.collection .collection-item.avatar:not(.circle-clipper) > .circle{
   background: #e6e6e6;
}