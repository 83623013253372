/*-------------------------------------------------
	Style file contain theme components style
-------------------------------------------------*/

@charset "UTF-8";


// Color
@import "components/color-variables";
@import "components/color-classes";

// Theme;
/*
  Autogenerated theme file
*/
@import "theme";

// Components
@import "theme-components/global";
@import "theme-components/helper";
@import "theme-components/typography";
@import "theme-components/grid";
@import "theme-components/color";
@import "theme-components/avatar";
@import "theme-components/dropdown";
@import "theme-components/badges";
@import "theme-components/icons";
@import "theme-components/pulse";

@import "theme-components/modal";
@import "theme-components/navbar";
@import "theme-components/sidenav";
@import "theme-components/slideout";

@import "theme-components/breadcrumb";
@import "theme-components/forms";
@import "theme-components/table";
@import "theme-components/card";
@import "theme-components/chart";
@import "theme-components/calendar";
@import "theme-components/custom-animations";

// Miscellaneous
@import "theme-components/miscellaneous";

// Footer
@import "theme-components/footer";

// Customizer
@import "theme-components/customizer"; // Customizer demo, remove in real project

.ck-editor__editable {
	min-height: 700px;
}
