/*----------------------------------------
    Tables
------------------------------------------*/

// DataTable
table {
   &.dataTable {
      &.dtr-inline {
         &.collapsed {
            & > tbody {
               & > tr[role="row"] {
                  & > td,
                  & > th {
                     &:first-child {
                        &:before {
                           top: 19px;
                           line-height: 15px;
                           text-indent: -1px !important;
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
