/*---------------------------------
    Typography
-----------------------------------*/
a {
	text-decoration: none;
 }
 
 html{
	line-height: 1.5;
 
	@media only screen and (min-width: 0) {
	  font-size: 14px;
	}
 
	@media only screen and (min-width: $medium-screen) {
	  font-size: 14.5px;
	}
 
	@media only screen and (min-width: $large-screen) {
	  font-size: 15px;
	}
 
	font-family: $content !important;
	font-weight: normal;
	color: $body-font-color;
 }
 h1, h2, h3, h4, h5, h6 {
	font-family: $title;
	 font-weight: 400;
	 line-height: 1.1;
	color: $title-font-color;
 }
 p{
	font-family: $content;
 }
 
 // Header Styles
 h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { 
	font-weight: inherit;
 }
 h1 { font-size: $h1-fontsize; line-height: 110%; margin: ($h1-fontsize / 2) 0 ($h1-fontsize / 2.5) 0;}
 h2 { font-size: $h2-fontsize; line-height: 110%; margin: ($h2-fontsize / 2) 0 ($h2-fontsize / 2.5) 0;}
 h3 { font-size: $h3-fontsize; line-height: 110%; margin: ($h3-fontsize / 2) 0 ($h3-fontsize / 2.5) 0;}
 h4 { font-size: $h4-fontsize; line-height: 110%; margin: ($h4-fontsize / 2) 0 ($h4-fontsize / 2.5) 0;}
 h5 { font-size: $h5-fontsize; line-height: 110%; margin: ($h5-fontsize / 2) 0 ($h5-fontsize / 2.5) 0;}
 h6 { font-size: $h6-fontsize; line-height: 110%; margin: ($h6-fontsize / 2) 0 ($h6-fontsize / 2.5) 0;}
 
 // Text Styles
 em { font-style: italic; }
 strong { font-weight: 500; }
 small { font-size: 75%; }
 .light { font-weight: 300; }
 .thin { font-weight: 200; }
 
 
 .flow-text{
	font-weight: 300;
	$i: 0;
	@while $i <= $intervals {
	  @media only screen and (min-width : 360 + ($i * $interval-size)) {
		 font-size: 1.2rem * (1 + (.02 * $i));
	  }
	  $i: $i + 1;
	}
 
	// Handle below 360px screen
	@media only screen and (max-width: 360px) {
	  font-size: 1.2rem;    
	}
 }
 
 @media only screen and (max-width: 768px) {
	.nav-expanded .brand-sidebar{
	  background: transparent !important;
	}
	.brand-sidebar .logo-wrapper{
	  text-align: center;
	  background: transparent;
	  padding: 17px 18px !important;
	}
 }

 // Helper classes 
.small {
	font-size: 1.0rem !important;
}
.medium-small {
	font-size: 0.9rem !important;
}
.ultra-small {
	font-size: 0.8rem !important;
}
small {
	font-size: 0.8rem;
}
.strong {
	font-weight: 600;
}
h4 {
	&.header {
		line-height: 2.508rem;
		margin: 1.14rem 0 0.912rem 0;
		font-size: 1.4rem;
		font-weight: 400;
		text-transform: uppercase;
	}
	&.header2 {
		font-size: 1.1rem;
		font-weight: 400;
		text-transform: uppercase;
	}
}
p {
	&.title {
		font-size: 1.3rem;
	}
	&.header {
		font-size: 1rem;
		font-weight: 500;
		text-transform: uppercase;
	}
}
li {
	&.li-hover:hover {
		background: transparent !important;
	}
	&.search-out:hover {
		background: transparent;
	}
	&.mobile:before {
		background: #f7464a;
	}
	&.kitchen:before {
		background: #46bfbd;
	}
	&.home:before {
		background: #fdb45c;
	}
}
.more-text {
	padding: 5px 20px;
	font-weight: 500;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.valign-demo {
	height: 400px;
	background-color: #ddd;
}
.margin {
	margin: 0 !important;
}

.uppercase{
	text-transform: uppercase;
}
