.list-actions {
   .confirm-action {
      color: red;
   }
}

.sortable-ghost {
   color: #fff;
   background-color: #cecece;
}

.my-handle {
   cursor: move;
   cursor: -webkit-grabbing;
}
