/*----------------------------------------
    Calender
------------------------------------------*/

#full-calendar {
	padding-top: 30px;
}
#calendar {
	h2 {
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 35px;
	}
	.fc-day-header {
		text-transform: uppercase;
		font-weight: 400;
	}
}
#external-events {
	padding-top: 50px;
	.fc-event {
		color: $white;
		text-decoration: none;
		padding: 5px;
		margin-bottom: 10px;
		cursor: all-scroll;
		border: none;
	}
}
.fc {
	button {
		background: $white;
	}
	td {
		border-width: 0 !important;
	}
	th {
		border-width: 0 !important;
	}
}
.fc-state-active {
	color: $primary-color !important;
}
.fc-state-down {
	color: $primary-color !important;
}