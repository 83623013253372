/*----------------------------------------
  Healper Classes
------------------------------------------*/

/* Width */
$width: width-10, width-20, width-30, width-40, width-50, width-60, width-70, width-80, width-90, width-100;
$width-v: 10% !important, 20% !important, 30% !important, 40% !important, 50% !important, 60% !important, 70% !important,
   80% !important, 90% !important, 100% !important;
@for $i from 1 through 10 {
   .#{ nth($width, $i)} {
      width: nth($width-v, $i);
   }
}

/* Border Radius */
$border: border-radius-1, border-radius-2, border-radius-3, border-radius-4, border-radius-5, border-radius-6,
   border-radius-7, border-radius-8, border-radius-9, border-radius-10;
$border-radius: 1px !important, 2px !important, 3px !important, 4px !important, 5px !important, 6px !important,
   7px !important, 8px !important, 9px !important, 10px !important;
@for $i from 1 through 10 {
   .#{ nth($border, $i)} {
      border-radius: nth($border-radius, $i);
   }
}
.border-round {
   border-radius: 150px !important;
}

/* Font Weight */
$font: font-weight-100, font-weight-200, font-weight-300, font-weight-400, font-weight-500, font-weight-600, font-weight-700,
   font-weight-800, font-weight-900;
$font-weight: 100 !important, 200 !important, 300 !important, 400 !important, 500 !important, 600 !important, 700 !important,
   800 !important, 900 !important;
@for $i from 1 through 9 {
   .#{ nth($font, $i)} {
      font-weight: nth($font-weight, $i);
   }
}

.m-0 {
   margin: 0 !important;
}
/* Margin Top */
$mt: mt-0, mt-1, mt-2, mt-3, mt-4, mt-5, mt-6, mt-7, mt-8, mt-9, mt-10;
$margin-top: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($mt, $i)} {
      margin-top: nth($margin-top, $i);
   }
}

/* Margin Right */
$mr: mr-0, mr-1, mr-2, mr-3, mr-4, mr-5, mr-6, mr-7, mr-8, mr-9, mr-10;
$margin-right: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($mr, $i)} {
      margin-right: nth($margin-right, $i);
   }
}

/* Margin Left */
$ml: ml-0, ml-1, ml-2, ml-3, ml-4, ml-5, ml-6, ml-7, ml-8, ml-9, ml-10;
$margin-left: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($ml, $i)} {
      margin-left: nth($margin-left, $i);
   }
}

/* Margin Bottom */
$mb: mb-0, mb-1, mb-2, mb-3, mb-4, mb-5, mb-6, mb-7, mb-8, mb-9, mb-10;
$margin-bottom: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($mb, $i)} {
      margin-bottom: nth($margin-bottom, $i);
   }
}

/* Padding All */
$padding-all: padding-1, padding-2, padding-3, padding-4, padding-5, padding-6, padding-7, padding-8, padding-9, padding-10;
$padding: 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important, 7% !important,
   8% !important, 9% !important, 10% !important;
@for $i from 1 through 10 {
   .#{ nth($padding-all, $i)} {
      padding: nth($padding, $i);
   }
}

.p-0 {
   padding: 0 !important;
}

/* Padding Top */
$pt: pt-0, pt-1, pt-2, pt-3, pt-4, pt-5, pt-6, pt-7, pt-8, pt-9, pt-10;
$padding-top: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($pt, $i)} {
      padding-top: nth($padding-top, $i);
   }
}

/* Padding Right */
$pr: pr-0, pr-1, pr-2, pr-3, pr-4, pr-5, pr-6, pr-7, pr-8, pr-9, pr-10;
$padding-right: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($pr, $i)} {
      padding-right: nth($padding-right, $i);
   }
}

/* Padding Right */
$pl: pl-0, pl-1, pl-2, pl-3, pl-4, pl-5, pl-6, pl-7, pl-8, pl-9, pl-10;
$padding-left: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($pl, $i)} {
      padding-left: nth($padding-left, $i);
   }
}

/* Padding Bottom */
$pb: pb-0, pb-1, pb-2, pb-3, pb-4, pb-5, pb-6, pb-7, pb-8, pb-9, pb-10;
$padding-bottom: 0% !important, 1% !important, 2% !important, 3% !important, 4% !important, 5% !important, 6% !important,
   7% !important, 8% !important, 9% !important, 10% !important;
@for $i from 1 through 11 {
   .#{ nth($pb, $i)} {
      padding-bottom: nth($padding-bottom, $i);
   }
}

/* Minimum Height */
$min-height: min-height-100, min-height-150, min-height-200, min-height-250, min-height-300, min-height-350, min-height-400,
   min-height-450, min-height-500, min-height-550;
$mh: 100px !important, 150px !important, 200px !important, 250px !important, 300px !important, 350px !important,
   400px !important, 450px !important, 500px !important, 550px !important;
@for $i from 1 through 5 {
   .#{ nth($min-height, $i)} {
      min-height: nth($mh, $i);
   }
}

@media (min-width: 576px) {
   .left-align-sm {
      text-align: left !important;
   }
   .right-align-sm {
      text-align: right !important;
   }
   .center-align-sm {
      text-align: center !important;
   }
}

@media (min-width: 768px) {
   .left-align-md {
      text-align: left !important;
   }
   .right-align-md {
      text-align: right !important;
   }
   .center-align-md {
      text-align: center !important;
   }
}

@media (min-width: 992px) {
   .left-align-lg {
      text-align: left !important;
   }
   .right-align-lg {
      text-align: right !important;
   }
   .center-align-lg {
      text-align: center !important;
   }
}

@media (min-width: 1200px) {
   .left-align-xl {
      text-align: left !important;
   }
   .right-align-xl {
      text-align: right !important;
   }
   .center-align-xl {
      text-align: center !important;
   }
}

.text-lowercase {
   text-transform: lowercase !important;
}

.text-uppercase {
   text-transform: uppercase !important;
}

.text-capitalize {
   text-transform: capitalize !important;
}

/* Icon Background Round */

.background-round {
   background-color: rgba(0, 0, 0, 0.18);
   padding: 15px;
   border-radius: 50%;
}

/* opacity 0 */

.opacity-0 {
   opacity: 0;
}

/* Border None & Shadow None */
.border-none {
   border-top: none !important;
   border-bottom: none !important;
   border-left: none !important;
   border-right: none !important;
}
.box-shadow-none {
   box-shadow: none !important;
}

/* Margin 0 & Padding 0 */
.no-margin {
   margin: 0% !important;
}
.no-padding {
   padding: 0% !important;
}

/* Position */
.position-absolute {
   position: absolute;
}

.position-none {
  position: unset;
}

/* Vertical Align */
.vertical-align-center {
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
}

.vertical-align-bottom {
   vertical-align: bottom;
}

.vertical-align-super {
   vertical-align: super;
}

// Vertical Text
.vertical-text-top {
   vertical-align: text-top !important;
}
.vertical-text-sub {
   vertical-align: sub;
}
.vertical-text-middle {
   vertical-align: middle;
}

// Vertical High

.height-100vh {
   height: 100vh;
}

/* Line Height */
.line-height-0 {
   line-height: 0px !important;
}

/* Icon Background */
.icon-bg-circle {
   color: $white;
   padding: 0.4rem;
   border-radius: 50%;
}
.text-shadow {
   text-shadow: -1px 2px 4px rgba(0, 0, 0, 1);
}

// Display Css
.display-none {
   display: none;
}
.display-inline {
   display: inline;
}
.display-block {
   display: block;
}
.display-grid {
   display: grid;
}
.display-flex {
   display: flex;
}

// Float

.float-none {
   float: none !important;
}
.float-left {
   float: left !important;
}
.float-right {
   float: right !important;
}
.float-inherit {
   float: inherit !important;
}

.clear-both {
   clear: both;
}

ul.list-type-bullet {
   padding-left: 40px;
   list-style-type: inherit;
   li {
      list-style-type: inherit;
   }
}

// Justify Content
.justify-content-end {
   justify-content: flex-end;
}

// Text Ellipsis
.text-ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
