// Color
@import "../components/color-variables";
@import "../components/color-classes";

/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value
             / nth($conversion-factors, index($convertable-units, unit($value)))
             * nth($conversion-factors, index($convertable-units, $unit));
  }
  
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  
  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  
  $conversion-map: ( 
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );
    
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  
  @return 90deg - convert-angle($value, 'deg');
}

/// Mixin printing a linear-gradient 
/// as well as a plain color fallback 
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {String | List | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }  
  background: nth(nth($color-stops, 1), 1);  
  background: linear-gradient($direction, $color-stops) !important;
}



/// Background Gradient
$gradient-colors: (
  gradient-45deg-light-blue-teal: (
    'start-color' : color("light-blue", "lighten-4"),
      'end-color' : color("teal", "accent-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-indigo-light-blue: (
    'start-color' : color("indigo", "darken-1"),
      'end-color' : color("light-blue", "lighten-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-light-blue-indigo: (
    'start-color' : color("light-blue", "lighten-4"),
      'end-color' : color("indigo", "lighten-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-yellow-green: (
    'start-color' : color("yellow", "accent-1"),
      'end-color' : color("green", "accent-1"),
      'direction' : 45deg,
  ),
  gradient-45deg-orange-deep-orange: (
    'start-color' : color("orange", "lighten-4"),
      'end-color' : color("deep-orange", "lighten-4"),
      'direction' : 45deg,
  ),
  gradient-45deg-deep-purple-purple: (
    'start-color' : color("deep-purple", "lighten-4"),
      'end-color' : color("purple", "lighten-5"),
      'direction' : 45deg,
  ),
  gradient-45deg-red-pink: (
    'start-color' : color("red", "accent-2"),
      'end-color' : color("pink", "lighten-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-light-green-amber: (
    'start-color' : color("light-green", "lighten-3"),
      'end-color' : color("amber", "lighten-5"),
      'direction' : 45deg,
  ),
  gradient-45deg-amber-amber: (
    'start-color' : color("amber", "darken-4"),
      'end-color' : color("amber", "lighten-1"),
      'direction' : 45deg,
  ),
  gradient-45deg-purple-pink: (
    'start-color' : color("purple", "accent-1"),
      'end-color' : color("pink", "lighten-5"),
      'direction' : 45deg,
  ),
  gradient-45deg-teal-cyan: (
    'start-color' : color("teal", "lighten-5"),
      'end-color' : color("cyan", "accent-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-blue-grey-blue-grey: (
    'start-color' : color("blue-grey", "lighten-4"),
      'end-color' : color("blue-grey", "darken-1"),
      'direction' : 45deg,
  ),
  gradient-45deg-orange-amber: (
    'start-color' : color("orange" , "darken-4"),
      'end-color' : color("blue", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-indigo-blue: (
    'start-color' : color("indigo" , "darken-2"),
      'end-color' : color("blue", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-brown-brown: (
    'start-color' : color("brown" , "darken-1"),
      'end-color' : color("blue", "lighten-4"),
      'direction' : 45deg,
  ),
  gradient-45deg-blue-grey-blue: (
    'start-color' : color("blue-grey" , "darken-4"),
      'end-color' : color("blue", "accent-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-purple-deep-orange: (
    'start-color' : color("purple" , "darken-1"),
      'end-color' : color("deep-orange", "accent-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-green-teal: (
    'start-color' : color("green" , "darken-1"),
      'end-color' : color("teal", "accent-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-purple-light-blue: (
    'start-color' : color("purple" , "accent-2"),
      'end-color' : color("light-blue", "lighten-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-cyan-cyan: (
    'start-color' : color("cyan" , "accent-2"),
      'end-color' : color("cyan", "accent-3"),
      'direction' : 45deg,
  ),
  gradient-45deg-yellow-teal: (
    'start-color' : color("yellow" , "lighten-4"),
      'end-color' : color("teal", "accent-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-purple-deep-purple: (
    'start-color' : color("purple" , "darken-2"),
      'end-color' : color("deep-purple", "accent-2"),
      'direction' : 45deg,
  ),  
  gradient-45deg-cyan-light-green: (
    'start-color' : color("cyan" , "darken-2"),
      'end-color' : color("light-green", "accent-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-purple-amber: (
    'start-color' : color("purple" , "accent-3"),
      'end-color' : color("amber", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-indigo-purple: (
    'start-color' : color("indigo" , "darken-2"),
      'end-color' : color("purple", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-deep-purple-blue: (
    'start-color' : color("deep-purple" , "accent-4"),
      'end-color' : color("blue", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-deep-orange-orange: (
    'start-color' : color("deep-orange" , "darken-4"),
      'end-color' : color("orange", "darken-2"),
      'direction' : 45deg,
  ),
  gradient-45deg-light-blue-cyan: (
    'start-color' : color("light-blue" , "darken-2"),
      'end-color' : color("cyan", "lighten-1"),
      'direction' : 45deg,
  ),
  gradient-45deg-blue-indigo: (
    'start-color' : color("blue" , "accent-4"),
      'end-color' : color("indigo", "darken-1"),
      'direction' : 45deg,
  ),
  gradient-45deg-semi-dark: (
    'start-color' : #00AEFF,
      'end-color' : #3369E6,
      'direction' : -90deg,
  ),
);

// gradient directional
// @mixin gradient-directional($start-color: $gray-700, $end-color: $gray-800, $deg: 45deg) {
//   background-image: linear-gradient($deg, $start-color, $end-color);
//   background-repeat: repeat-x;
// }

/*
  Gradient color mixin
*/

@each $gcolor_name,
$gcolor in $gradient-colors {
    $start-color: map-get($gcolor, start-color);
    $end-color: map-get($gcolor, end-color);
    $direction: map-get($gcolor, direction);
    .#{$gcolor_name} {
        @include linear-gradient($direction, $start-color, $end-color);
        &.gradient-shadow{
          box-shadow: 0 6px 20px 0 rgba($end-color,0.5) !important;
        }
    }
}